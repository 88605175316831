import React from "react";
import {Link, graphql} from "gatsby";
import {MDXRenderer} from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import Meta from "../components/meta";

import backIcon from "../images/back.svg";
import Img from "gatsby-image/index";
import RequestDemoMiniForm from "../components/request-demo-mini-form";

const excerptHtml = require('excerpt-html');

const BlogPostTemplate = ({data, pageContext, location}) => {
    // const post = data.mdx;
    if (!data) {
        return false;
    }
    const post = data.mediumFeed;
    const siteTitle = data.site.siteMetadata.title;
    const {previous, next} = pageContext;
    const content = post.content.replace(/<figure>(.*?)<\/figure>/, '');

    return (
        <Layout location={location} title={siteTitle}>
            <Meta
                title={post.title}
                description={post.description || post.excerpt || excerptHtml(content)}
            />
            <div className="banner knowledge_banner knowledge_center_banner">
                <div className={'wrapper'}>
                    <div className="banner_content">
                        <div className="wrapper">
                            <div className="left_col">
                                <Link to={'/knowledge-center'} className="back"><img src={backIcon} alt="back"/> Back to
                                    knowledge center</Link>
                                <h2>{post.title}</h2>
                                <h3>{post.date}</h3>
                            </div>
                            <div className="right_col">
                                <div className={'col'}>
                                    {post.featuredImg && (
                                        <Img fluid={post.featuredImg.childImageSharp.fluid}/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="knowledge_2">
                <div className="wrapper">
                    {/*<MDXRenderer>{post.body}</MDXRenderer>*/}
                    <div dangerouslySetInnerHTML={{__html: content}}/>
                    <div className={'pagination'}>
                        {previous && (
                            <Link to={'/knowledge-center/' + previous.slug + '/'} rel="next" className="next_article">
                                <h3>Next Article</h3>
                                <p>{previous.title}</p>
                                <span><img src={backIcon} alt="next"/></span>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <div className="salse_col">
                <div className="wrapper">
                    <div className="in_sales cf">
                        <div className="text_right">
                            <h3>Want to increase your sales?</h3>
                        </div>
                        <div className="con_mail">
                            <RequestDemoMiniForm/>
                        </div>
                    </div>
                </div>
            </div>
            {/*<li>
                {previous && (
                    <Link to={'/knowledge-center/'+previous.frontmatter.slug+'/'} rel="prev">
                        ← {previous.frontmatter.title}
                    </Link>
                )}
            </li>*/}
        </Layout>
    )
}

export default BlogPostTemplate;

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            fluid(quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
            }
        }
    }
`;

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        mediumFeed(slug: { eq: $slug }) {
            id
            title
            content
            date(formatString: "MMMM DD, YYYY")
            featuredImg { ...fluidImage }
        }
    }
`
// export const pageQuery = graphql`
//     query BlogPostBySlug($slug: String!) {
//         site {
//             siteMetadata {
//                 title
//             }
//         }
//         mdx(frontmatter: { slug: { eq: $slug } }) {
//             id
//             excerpt(pruneLength: 160)
//             body
//             frontmatter {
//                 title
//                 date(formatString: "MMMM DD, YYYY")
//                 image { ...fluidImage }
//             }
//         }
//     }
// `